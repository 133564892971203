// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PostStat(Props) {
  var title = Props.title;
  var value = Props.value;
  return React.createElement("div", {
              className: [
                  "flex",
                  "flex-col",
                  "tracking-tight",
                  "mr-8",
                  "mt-4",
                  "last:m-0"
                ].join(" ")
            }, React.createElement("span", undefined, title), React.createElement("span", {
                  className: "font-bold"
                }, value));
}

var make = PostStat;

export {
  make ,
  
}
/* react Not a pure module */
