// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Option from "bs-platform/lib/es6/belt_Option.js";

function Divider(Props) {
  var className = Props.className;
  return React.createElement("hr", {
              className: [
                  "block",
                  "w-24",
                  "h-0.5",
                  "bg-gray-200",
                  "mx-auto",
                  Belt_Option.getWithDefault(className, "")
                ].join(" ")
            });
}

var make = Divider;

export {
  make ,
  
}
/* react Not a pure module */
