// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Gradient from "../../utils/Gradient.bs.js";

function PostHeading(Props) {
  var children = Props.children;
  var match = Gradient.getColors(children);
  return React.createElement("h1", {
              className: [
                  "font-bold",
                  "md:font-black",
                  "text-transparent",
                  "tracking-tight",
                  "max-w-2xl",
                  "mx-auto",
                  "text-6xl",
                  "md:text-7xl",
                  "pr-6",
                  "md:pr-24"
                ].join(" ")
            }, React.createElement("span", {
                  className: [
                      "inline-block",
                      "bg-clip-text",
                      "bg-gradient-to-r",
                      "from-" + match[0] + "-600",
                      "to-" + match[1] + "-600"
                    ].join(" ")
                }, children));
}

var make = PostHeading;

export {
  make ,
  
}
/* react Not a pure module */
