// Generated by ReScript, PLEASE EDIT WITH CARE

import * as SEO from "../../components/SEO.bs.js";
import * as React from "react";
import * as Footer from "../../components/Footer.bs.js";
import * as Divider from "../../components/Divider.bs.js";
import * as Js_json from "bs-platform/lib/es6/js_json.js";
import * as PostTag from "../../components/PostTag.bs.js";
import * as Nullable from "../../utils/Nullable.bs.js";
import * as PostStat from "./PostStat.bs.js";
import * as Belt_Array from "bs-platform/lib/es6/belt_Array.js";
import * as Caml_option from "bs-platform/lib/es6/caml_option.js";
import * as PostContent from "./PostContent.bs.js";
import * as PostHeading from "./PostHeading.bs.js";
import * as ExternalLink from "../../components/ExternalLink.bs.js";
import * as NavigationBar from "../../components/NavigationBar.bs.js";
import * as Js_null_undefined from "bs-platform/lib/es6/js_null_undefined.js";
import Ko from "date-fns/locale/ko";
import * as DateFns$ReasonDateFns from "reason-date-fns/src/DateFns.bs.js";

var Raw = {};

var query = (graphql`
  query BlogPostBySlug($slug: String!)  {
    site  {
      siteMetadata  {
        title
        author  {
          name
          summary
        }
        description
        siteUrl
        social  {
          github
          facebook
          twitter
        }
      }
    }
    markdownRemark(fields: {slug: {eq: $slug}})  {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter  {
        title
        description
        createdAt
        updatedAt
        tags
      }
    }
  }
`);

function parse(value) {
  var value$1 = value.site;
  var tmp;
  if (value$1 == null) {
    tmp = undefined;
  } else {
    var value$2 = value$1.siteMetadata;
    var tmp$1;
    if (value$2 == null) {
      tmp$1 = undefined;
    } else {
      var value$3 = value$2.title;
      var value$4 = value$2.author;
      var tmp$2;
      if (value$4 == null) {
        tmp$2 = undefined;
      } else {
        var value$5 = value$4.name;
        var value$6 = value$4.summary;
        tmp$2 = {
          name: !(value$5 == null) ? value$5 : undefined,
          summary: !(value$6 == null) ? value$6 : undefined
        };
      }
      var value$7 = value$2.description;
      var value$8 = value$2.siteUrl;
      var value$9 = value$2.social;
      var tmp$3;
      if (value$9 == null) {
        tmp$3 = undefined;
      } else {
        var value$10 = value$9.github;
        var value$11 = value$9.facebook;
        var value$12 = value$9.twitter;
        tmp$3 = {
          github: !(value$10 == null) ? value$10 : undefined,
          facebook: !(value$11 == null) ? value$11 : undefined,
          twitter: !(value$12 == null) ? value$12 : undefined
        };
      }
      tmp$1 = {
        title: !(value$3 == null) ? value$3 : undefined,
        author: tmp$2,
        description: !(value$7 == null) ? value$7 : undefined,
        siteUrl: !(value$8 == null) ? value$8 : undefined,
        social: tmp$3
      };
    }
    tmp = {
      siteMetadata: tmp$1
    };
  }
  var value$13 = value.markdownRemark;
  var tmp$4;
  if (value$13 == null) {
    tmp$4 = undefined;
  } else {
    var value$14 = value$13.excerpt;
    var value$15 = value$13.html;
    var value$16 = value$13.frontmatter;
    var tmp$5;
    if (value$16 == null) {
      tmp$5 = undefined;
    } else {
      var value$17 = value$16.title;
      var value$18 = value$16.description;
      var value$19 = value$16.createdAt;
      var value$20 = value$16.updatedAt;
      var value$21 = value$16.tags;
      tmp$5 = {
        title: !(value$17 == null) ? value$17 : undefined,
        description: !(value$18 == null) ? value$18 : undefined,
        createdAt: !(value$19 == null) ? Caml_option.some(value$19) : undefined,
        updatedAt: !(value$20 == null) ? Caml_option.some(value$20) : undefined,
        tags: !(value$21 == null) ? value$21.map(function (value) {
                if (!(value == null)) {
                  return value;
                }
                
              }) : undefined
      };
    }
    tmp$4 = {
      id: value$13.id,
      excerpt: !(value$14 == null) ? value$14 : undefined,
      html: !(value$15 == null) ? value$15 : undefined,
      frontmatter: tmp$5
    };
  }
  return {
          site: tmp,
          markdownRemark: tmp$4
        };
}

function serialize(value) {
  var value$1 = value.markdownRemark;
  var markdownRemark;
  if (value$1 !== undefined) {
    var value$2 = value$1.frontmatter;
    var frontmatter;
    if (value$2 !== undefined) {
      var value$3 = value$2.tags;
      var tags = value$3 !== undefined ? value$3.map(function (value) {
              if (value !== undefined) {
                return value;
              } else {
                return null;
              }
            }) : null;
      var value$4 = value$2.updatedAt;
      var updatedAt = value$4 !== undefined ? Caml_option.valFromOption(value$4) : null;
      var value$5 = value$2.createdAt;
      var createdAt = value$5 !== undefined ? Caml_option.valFromOption(value$5) : null;
      var value$6 = value$2.description;
      var description = value$6 !== undefined ? value$6 : null;
      var value$7 = value$2.title;
      var title = value$7 !== undefined ? value$7 : null;
      frontmatter = {
        title: title,
        description: description,
        createdAt: createdAt,
        updatedAt: updatedAt,
        tags: tags
      };
    } else {
      frontmatter = null;
    }
    var value$8 = value$1.html;
    var html = value$8 !== undefined ? value$8 : null;
    var value$9 = value$1.excerpt;
    var excerpt = value$9 !== undefined ? value$9 : null;
    var value$10 = value$1.id;
    markdownRemark = {
      id: value$10,
      excerpt: excerpt,
      html: html,
      frontmatter: frontmatter
    };
  } else {
    markdownRemark = null;
  }
  var value$11 = value.site;
  var site;
  if (value$11 !== undefined) {
    var value$12 = value$11.siteMetadata;
    var siteMetadata;
    if (value$12 !== undefined) {
      var value$13 = value$12.social;
      var social;
      if (value$13 !== undefined) {
        var value$14 = value$13.twitter;
        var twitter = value$14 !== undefined ? value$14 : null;
        var value$15 = value$13.facebook;
        var facebook = value$15 !== undefined ? value$15 : null;
        var value$16 = value$13.github;
        var github = value$16 !== undefined ? value$16 : null;
        social = {
          github: github,
          facebook: facebook,
          twitter: twitter
        };
      } else {
        social = null;
      }
      var value$17 = value$12.siteUrl;
      var siteUrl = value$17 !== undefined ? value$17 : null;
      var value$18 = value$12.description;
      var description$1 = value$18 !== undefined ? value$18 : null;
      var value$19 = value$12.author;
      var author;
      if (value$19 !== undefined) {
        var value$20 = value$19.summary;
        var summary = value$20 !== undefined ? value$20 : null;
        var value$21 = value$19.name;
        var name = value$21 !== undefined ? value$21 : null;
        author = {
          name: name,
          summary: summary
        };
      } else {
        author = null;
      }
      var value$22 = value$12.title;
      var title$1 = value$22 !== undefined ? value$22 : null;
      siteMetadata = {
        title: title$1,
        author: author,
        description: description$1,
        siteUrl: siteUrl,
        social: social
      };
    } else {
      siteMetadata = null;
    }
    site = {
      siteMetadata: siteMetadata
    };
  } else {
    site = null;
  }
  return {
          site: site,
          markdownRemark: markdownRemark
        };
}

function serializeVariables(inp) {
  return {
          slug: inp.slug
        };
}

function makeVariables(slug, param) {
  return {
          slug: slug
        };
}

var BlogPostBySlug = {
  Raw: Raw,
  query: query,
  parse: parse,
  serialize: serialize,
  serializeVariables: serializeVariables,
  makeVariables: makeVariables
};

function decodeString(str) {
  return Js_null_undefined.fromOption(Js_json.decodeString(str));
}

function Post(Props) {
  var rawData = Props.data;
  var pageContext = Props.pageContext;
  var markdown = Nullable.getExn(rawData.markdownRemark);
  var matter = Nullable.getExn(markdown.frontmatter);
  var siteMetadata = Nullable.getExn(Nullable.flatMap(rawData.site, (function (site) {
              return site.siteMetadata;
            })));
  var author = Nullable.getExn(siteMetadata.author);
  var social = Nullable.getExn(siteMetadata.social);
  var title = Nullable.getExn(matter.title);
  var description = Caml_option.nullable_to_opt(matter.description) !== undefined ? Nullable.getExn(matter.description) : Nullable.getExn(markdown.excerpt);
  var createdAt = Nullable.getExn(Nullable.flatMap(matter.createdAt, decodeString));
  var updatedAt = Nullable.getExn(Nullable.flatMap(matter.updatedAt, decodeString));
  var tags = Nullable.getExn(matter.tags);
  return React.createElement(React.Fragment, undefined, React.createElement(SEO.make, {
                  title: title + " / seob.dev",
                  description: description,
                  url: "https://seob.dev/posts/" + pageContext.slug + "/",
                  children: null
                }, React.createElement("meta", {
                      content: createdAt,
                      property: "article:published_time"
                    }), React.createElement("meta", {
                      content: updatedAt,
                      property: "article:modified_time"
                    })), React.createElement(NavigationBar.make, {}), React.createElement("article", {
                  className: "container mx-auto py-16 px-4"
                }, React.createElement(PostHeading.make, {
                      children: title
                    }), React.createElement("div", {
                      className: "flex flex-col md:flex-row max-w-2xl mx-auto pt-4"
                    }, React.createElement(PostStat.make, {
                          title: "마지막 업데이트",
                          value: React.createElement("time", {
                                dateTime: updatedAt
                              }, DateFns$ReasonDateFns.formatWithOptions({
                                    locale: Ko
                                  }, "yyyy년 M월 d일", new Date(updatedAt)))
                        }), React.createElement(PostStat.make, {
                          title: "태그",
                          value: Belt_Array.map(tags, (function (tag) {
                                  return React.createElement(PostTag.make, {
                                              children: Nullable.getExn(tag)
                                            });
                                }))
                        }), React.createElement(PostStat.make, {
                          title: "글쓴이",
                          value: React.createElement(ExternalLink.make, {
                                children: "이현섭",
                                href: "https://twitter.com/" + Nullable.getExn(social.twitter)
                              })
                        })), React.createElement(Divider.make, {
                      className: "mt-24 mb-16"
                    }), React.createElement(PostContent.make, {
                      html: Nullable.getExn(markdown.html)
                    }), React.createElement(Divider.make, {
                      className: "mt-16"
                    }), React.createElement("section", {
                      className: "max-w-2xl mx-auto pt-12"
                    }, React.createElement("h4", {
                          className: "text-gray-900 text-xl md:text-2xl font-bold mb-4"
                        }, "글쓴이"), React.createElement("div", {
                          className: "flex items-center"
                        }, React.createElement("img", {
                              "aria-hidden": true,
                              className: "rounded-full h-12 w-12",
                              alt: "",
                              src: "https://ko.gravatar.com/userimage/99798884/1e93a7577245566cc5a088b05f461ca2.png"
                            }), React.createElement("div", {
                              className: "pl-4"
                            }, React.createElement("div", {
                                  className: "flex text-gray-900 text-md md:text-lg"
                                }, React.createElement("span", {
                                      className: "font-bold"
                                    }, Nullable.getExn(author.name)), React.createElement("span", {
                                      className: "inline-block mx-2 text-gray-200"
                                    }, "|"), React.createElement("span", undefined, Nullable.getExn(author.summary))), React.createElement("div", {
                                  className: "flex text-gray-700 text-sm md:text-md"
                                }, React.createElement(ExternalLink.make, {
                                      className: "mr-2",
                                      children: "GitHub",
                                      href: "https://github.com/" + Nullable.getExn(social.github)
                                    }), React.createElement(ExternalLink.make, {
                                      className: "mr-2",
                                      children: "Twitter",
                                      href: "https://twitter.com/" + Nullable.getExn(social.twitter)
                                    }), React.createElement(ExternalLink.make, {
                                      children: "Facebook",
                                      href: "https://www.facebook.com/" + Nullable.getExn(social.facebook)
                                    })))))), React.createElement(Footer.make, {}));
}

var make = Post;

var $$default = Post;

export {
  BlogPostBySlug ,
  query ,
  decodeString ,
  make ,
  $$default ,
  $$default as default,
  
}
/* query Not a pure module */
