// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

import './PostContent.css';
;

function PostContent(Props) {
  var html = Props.html;
  return React.createElement("div", {
              className: "post-content text-base font-medium text-gray-700",
              dangerouslySetInnerHTML: {
                __html: html
              }
            });
}

var make = PostContent;

export {
  make ,
  
}
/*  Not a pure module */
