// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

function PostTag(Props) {
  var children = Props.children;
  return React.createElement("span", {
              className: [
                  "inline-block",
                  "font-bold",
                  "text-sm",
                  "bg-yellow-400",
                  "rounded-lg",
                  "py-1",
                  "px-2",
                  "mr-2",
                  "last:m-0"
                ].join(" ")
            }, children);
}

var make = PostTag;

export {
  make ,
  
}
/* react Not a pure module */
